import { useKeycloak } from "@react-keycloak/web";
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import AppConfig from "../AppConfig";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Nav = () => {
  const { keycloak } = useKeycloak();
  const username = keycloak.authenticated && (keycloak.tokenParsed.name);
  const useremail = keycloak.authenticated && (keycloak.tokenParsed.email);
  const userimage ='/profile.png';

  const getBillingURL = () => {
    fetch(AppConfig.api_base_url + 'v1/stripe/billing',
        {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + keycloak.token, // notice the Bearer before your token
          },
        })
        .then((response) => response.json())
        .then((data) => {
          window.location = data.data;
        })
        .catch((err) => {
          console.log(err.message);
        });
  }

  const navigation = [
    { name: 'Dashboard', href: '#', current: true, target: "", rel: "" },
    { name: 'Billing', href: '#', current: false, target: "", rel: "" },
    { name: 'API Catalogue', href: 'https://7x.ax/catalogue', current: false, target: "_blank", rel: "noopener noreferrer" },
    { name: '7x SDKs', href: 'https://github.com/orgs/7x-ax/repositories?q=sdk', current: false, target: "_blank", rel: "noopener noreferrer" },
    { name: 'Support', href: 'https://helpdesk.7x.ax', current: false, target: "_blank", rel: "noopener noreferrer" },
  ];

  return (
      <>
        <div className="min-h-full">
          <Disclosure as="nav" className="bg-red-600">
            {({ open }) => (
                <>
                  <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="flex h-16 items-center justify-between">
                      <div className="flex items-center">
                        <div className="flex-shrink-0">
                          <img
                              className="h-8 w-8"
                              src="/7x-100x100-6.svg"
                              alt="7x"
                          />
                        </div>
                        <div className="hidden md:block">
                          <div className="ml-10 flex items-baseline space-x-4">
                            {navigation.map((item) => (
                                <a
                                    key={item.name}
                                    href={item.href}
                                    target={item.target}
                                    rel={item.rel}
                                    className={classNames(
                                        item.current
                                            ? 'bg-red-700 text-white'
                                            : 'text-white hover:bg-red-500 hover:bg-opacity-75',
                                        'px-3 py-2 rounded-md text-sm font-medium'
                                    )}
                                    onClick={() => item.name === 'Billing' ? getBillingURL() : false}
                                    aria-current={item.current ? 'page' : undefined}
                                >
                                  {item.name}
                                </a>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="hidden md:block">
                        <div className="ml-4 flex items-center md:ml-6">
{/*                          <button
                              type="button"
                              className="rounded-full bg-red-600 p-1 text-red-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-red-600"
                          >
                            <span className="sr-only">View notifications</span>
                            <BellIcon className="h-6 w-6" aria-hidden="true" />
                          </button>*/}

                          {/* Profile dropdown */}
                          <Menu as="div" className="relative ml-3">
                            <div>
                              <Menu.Button className="flex max-w-xs items-center rounded-full bg-red-600 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-red-600">
                                <span className="sr-only">Open user menu</span>
                                <img className="h-8 w-8 rounded-full" src={userimage} alt="" />
                              </Menu.Button>
                            </div>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <Menu.Item key="Your Profile">
                                      {({ active }) => (
                                          <a
                                              href="#"
                                              onClick={() => keycloak.accountManagement()}
                                              className={classNames(
                                                  active ? 'bg-gray-100' : '',
                                                  'block px-4 py-2 text-sm text-gray-700'
                                              )}
                                          >
                                            Your profile
                                          </a>
                                      )}
                                    </Menu.Item>
                                    {!keycloak.authenticated && (
                                    <Menu.Item key="Login">
                                      {({ active }) => (
                                          <a
                                              href="#"
                                              onClick={() => keycloak.login()}
                                              className={classNames(
                                                  active ? 'bg-gray-100' : '',
                                                  'block px-4 py-2 text-sm text-gray-700'
                                              )}
                                          >
                                            Login
                                          </a>
                                      )}
                                    </Menu.Item>
                                    )}
                                {keycloak.authenticated && (
                                    <Menu.Item key="Logout">
                                      {({ active }) => (
                                          <a
                                              href="#"
                                              onClick={() => keycloak.logout()}
                                              className={classNames(
                                                  active ? 'bg-gray-100' : '',
                                                  'block px-4 py-2 text-sm text-gray-700'
                                              )}
                                          >
                                            Logout
                                          </a>
                                      )}
                                    </Menu.Item>
                                )}
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </div>
                      </div>
                      <div className="-mr-2 flex md:hidden">
                        {/* Mobile menu button */}
                        <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-red-600 p-2 text-red-200 hover:bg-red-500 hover:bg-opacity-75 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-red-600">
                          <span className="sr-only">Open main menu</span>
                          {open ? (
                              <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                          ) : (
                              <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                          )}
                        </Disclosure.Button>
                      </div>
                    </div>
                  </div>

                  <Disclosure.Panel className="md:hidden">
                    <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
                      {navigation.map((item) => (
                          <Disclosure.Button
                              key={item.name}
                              as="a"
                              href={item.href}
                              className={classNames(
                                  item.current
                                      ? 'bg-red-700 text-white'
                                      : 'text-white hover:bg-red-500 hover:bg-opacity-75',
                                  'block px-3 py-2 rounded-md text-base font-medium'
                              )}
                              onClick={() => item.name === 'Billing' ? getBillingURL() : false}
                              aria-current={item.current ? 'page' : undefined}
                          >
                            {item.name}
                          </Disclosure.Button>
                      ))}
                    </div>
                    <div className="border-t border-red-700 pt-4 pb-3">
                      <div className="flex items-center px-5">
                        <div className="flex-shrink-0">
                          <img className="h-10 w-10 rounded-full" src={userimage} alt="" />
                        </div>
                        <div className="ml-3">
                          <div className="text-base font-medium text-white">{username}</div>
                          <div className="text-sm font-medium text-red-300">{useremail}</div>
                        </div>
                        <button
                            type="button"
                            className="ml-auto flex-shrink-0 rounded-full bg-red-600 p-1 text-red-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-red-600"
                        >
                          <span className="sr-only">View notifications</span>
                          <BellIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                      <div className="mt-3 space-y-1 px-2">
                        {keycloak.authenticated && (
                            <Disclosure.Button
                                key="Your Profile"
                                as="a"
                                href="#"
                                onClick={() => keycloak.accountManagement()}
                                className="block rounded-md px-3 py-2 text-base font-medium text-white hover:bg-red-500 hover:bg-opacity-75"
                            >
                              Your profile ({useremail} )
                            </Disclosure.Button>
                            )}
                        {!keycloak.authenticated && (
                            <Disclosure.Button
                                key="Login"
                                as="a"
                                href="#"
                                onClick={() => keycloak.login()}
                                className="block rounded-md px-3 py-2 text-base font-medium text-white hover:bg-red-500 hover:bg-opacity-75"
                            >
                              Your profile
                            </Disclosure.Button>
                        )}
                        {keycloak.authenticated && (
                            <Disclosure.Button
                                key="Logout"
                                as="a"
                                href="#"
                                onClick={() => keycloak.logout()}
                                className="block rounded-md px-3 py-2 text-base font-medium text-white hover:bg-red-500 hover:bg-opacity-75"
                            >
                              Logout
                            </Disclosure.Button>
                        )}
                      </div>
                    </div>
                  </Disclosure.Panel>
                </>
            )}
          </Disclosure>

        </div>
      </>
  )

  /*return (
      <div>
        <div className="top-0 w-full flex flex-wrap">
          <section className="x-auto">
            <nav className="flex justify-between bg-gray-200 text-blue-800 w-screen">
              <div className="px-5 xl:px-12 py-6 flex w-full items-center">
                <h1 className="text-3xl font-bold font-heading">
                  Keycloak React AUTH.
                </h1>
                <ul className="hidden md:flex px-4 mx-auto font-semibold font-heading space-x-12">
                  <li>
                    <a className="hover:text-blue-800" href="/">
                      Home
                    </a>
                  </li>
                  <li>
                    <a className="hover:text-blue-800" href="/secured">
                      Secured Page
                    </a>
                  </li>
                </ul>
                <div className="hidden xl:flex items-center space-x-5">
                  <div className="hover:text-gray-200">
                    {!keycloak.authenticated && (
                        <button
                            type="button"
                            className="text-blue-800"
                            onClick={() => keycloak.login()}
                        >
                          Login
                        </button>
                    )}

                    {!!keycloak.authenticated && (
                        <button
                            type="button"
                            className="text-blue-800"
                            onClick={() => keycloak.logout()}
                        >
                          Logout ({keycloak.tokenParsed.preferred_username})
                        </button>
                    )}
                  </div>
                </div>
              </div>
            </nav>
          </section>
        </div>
      </div>
  );*/
};

export default Nav;